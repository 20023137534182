<template>
  <div>
    <div class="page-content p-normal">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="search.query" @keyup="searchFundingRequests" aria-label="search">
          </div>
        </div>
        <div class="col-12 col-md-6 text-center text-md-end mb-3 mb-md-0">
          <div class="change-table-view">
            <button :class="{active: status === null}" @click="getRequestsByStatus(null)">
              All
            </button>
            <button :class="{active: status === 'waiting_for_payment'}"
                    @click="getRequestsByStatus('waiting_for_payment')">
              In Review {{ waitingForPaymentTotal }}
            </button>
            <button :class="{active: status === 'pending'}" @click="getRequestsByStatus('pending')">
              Pending {{ pendingTotal }}
            </button>
          </div>
        </div>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Borrower</th>
            <th scope="col">MLO</th>
            <th scope="col">Lender</th>
            <th scope="col">Broker Wire</th>
            <th scope="col">Status / Score</th>
            <th scope="col">Submitted On</th>
            <th scope="col">Due to Salesperson</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(fr, index) in pendingRequests" :key="index">
            <td class="cursor-pointer table-borrower-name" @click="toggleModal('fundingDetails', fr.id)">
              {{ fr.borrowerName }}
            </td>
            <td>
              <div class="agents-avatars" v-if="isGranted('ROLE_BOOKKEEPER')">
                <a v-for="a in fr.agents" :key="fr.id + '_' + a.id" :href="backendUrl('/mlo/account/agent/' + a.id)" target="_blank">
                  <img :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
                </a>
              </div>
              <div class="agents-avatars" v-else>
                <img v-for="a in fr.agents" :key="fr.id + '_' + a.id" :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
              </div>
            </td>
            <td>{{ fr.lender }}</td>
            <td>
              <span v-tippy :content="capitalize(fr.type)">
                <span v-if="fr.type !== 'correspondent' || isGranted('ROLE_FUNDER')">
                  <b>{{ convertToCurrency(fr.wireAmount) }}</b>
                </span>
                <span v-else><b>N/A</b></span>
                ({{ formatDate(fr.wireDate) }})
              </span>
            </td>
            <td>
              <template v-if="isGranted(ROLE_BOOKKEEPER) || isGranted('ROLE_PROCESS_FUNDING_REQUESTS')">
                <div class="btn-group">
                  <div class="btn btn-br-default" :class="{
                    'btn-secondary': fr.status === 'pending',
                    'btn-danger': fr.status === 'error',
                    'btn-success': fr.status === 'waiting_for_payment' || fr.status === 'paid',
                  }" @click="toggleModal('scoreModal', fr.id)">
                    {{ fr.statusForHumans }}
                  </div>
                  <div class="btn btn-br-default" :class="{
                    'btn-danger': fr.score < 40,
                    'btn-primary': fr.score >= 40 && fr.score < 70,
                    'btn-success': fr.score >= 70,
                  }" @click="toggleModal('scoreModal', fr.id)">
                    <span>
                      0
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="btn btn-br-default" :class="{
                    'btn-secondary': fr.status === 'pending',
                    'btn-danger': fr.status === 'error',
                    'btn-success': fr.status === 'paid' || fr.status === 'waiting_for_payment',
                  }">
                  {{ fr.statusForHumans }}
                </div>
              </template>
            </td>
            <td>{{ fr.dateOfSubmissionF }}</td>
            <td>
              <b>{{ convertToCurrency(fr.dueToSalesperson) }}</b>
            </td>
            <td class="actions-buttons">
              <div class="d-flex">
                <button class="btn btn-primary" @click="toggleModal('fundingDetails', fr.id)">
                  Details
                </button>
                <button v-if="fr.isGranted.edit" class="btn btn-secondary" @click="toggleModal('editFunding', fr.id)">
                  Edit
                </button>
                <div>
                  <button class="btn ms-2 btn-control" :name="'fr_action' + index">
                    <img src="@/assets/icons/icon-more.svg" alt="">
                  </button>
                  <tippy boundary="window"
                         interactive="true"
                         trigger="click"
                         class="borrower-action"
                         zIndex="1039"
                         animateFill="false"
                         placement="bottom-end"
                         theme="light"
                         :to="'fr_action' + index">
                    <div class="table-menu-block">
                      <ul>
                        <li class="action-label">Actions</li>
                        <li v-if="fr.isGranted.pay" @click="pay(fr)" class="cursor-pointer">
                          <img src="@/assets/icons/icon-credit-card.svg" alt="">
                          Pay
                        </li>
                        <li v-if="fr.isGranted.doPayment" @click="openPaymentCustomModal(fr.id)" class="cursor-pointer">
                          <img src="@/assets/icons/icon-Add-Note.svg" alt="">
                          Custom Pay
                        </li>
                        <li v-if="fr.isGranted.delete" @click="deleteFunding(fr.id)" class="cursor-pointer">
                          <img src="@/assets/icons/icon-delete.svg" alt="">
                          Delete
                        </li>
                      </ul>
                    </div>
                  </tippy>
                </div>
              </div>
            </td>
          </tr>
            <tr v-if="pendingRequests.length === 0">
              <td colspan="8" class="text-center">
                <div class="m-5">
                  <strong class="no-result">No borrower yet.</strong><br>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        v-if="ui.loading === false"
        class="justify-content-start"
        :from="pagination.from"
        :to="pagination.to"
        :total="pagination.total"
        :page="pagination.page"
        :per-page="pagination.perPage"
        @update="getPendingRequests"/>
    </div>

    <transition name="component-fade" mode="out-in">
      <export-modal
        v-if="modals.exportModal === true"
        :selected-ids="getSelectedIds()"
        @close="modals.exportModal = false"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <funding-details-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.fundingDetails === true"
        @close="toggleModal"
        @editFunding="modals.editFunding = true"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <score-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.scoreModal === true"
        @close="toggleModal"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <edit-funding-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.editFunding === true"
        @close="toggleModal"/>
    </transition>
    <transition>
      <payment-modal v-if="modals.paymentModal === true"
                     :funding-request-id="selectedFundingRequestId"
                     :custom-fee="payment.customFee"
                     @close="toggleModal"/>
    </transition>
  </div>
</template>

<script>
import ExportModal from "./modals/ExportModal";
import FundingDetailsModal from "./modals/FundingDetailsModal";
import EditFundingModal from "./modals/EditFundingModal";
import ScoreModal from "./modals/ScoreModal";
import Pagination from "../../components/Pagination";
import PaymentModal from "./modals/PaymentModalOldVersion"

export default {
  name: "FundingRequestsPending",
  components: {ExportModal, FundingDetailsModal, EditFundingModal, ScoreModal, Pagination, PaymentModal},
  data() {
    return {
      showSelect: false,
      showModalNewFunding: false,
      showModalEditFunding: false,
      pendingRequests: [],
      transactions: [],
      selectAllCheckbox: false,
      selectedFundingRequestId: null,
      selectedCount: 0,
      waitingForPaymentTotal: 0,
      pendingTotal: 0,
      status: null,
      ui: {
        loading: false,
        pay: {loading: []}
      },
      modals: {
        exportModal: false,
        fundingDetails: false,
        editFunding: false,
        customFeeModal: false,
        scoreModal: false,
        paymentModal: false
      },
      payment: {
        customFee: false
      },
      search: {
        timer: null,
        query: null
      },
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        page: 1,
        perPage: 10
      }
    }
  },
  methods: {
    getPendingRequests(pagination = null) {
      this.ui.loading = true;
      let params = pagination ? pagination : this.pagination
      params.query = this.search.query
      params.status = this.status ? this.status : null
      this.$http.get('/api/v1/funding_requests/pending', {params: params})
        .then((res) => {
          this.ui.loading = false;
          this.pendingRequests = res.data.pendingRequests
          this.pagination = res.data.pagination
          this.pagination.total = Number.parseInt(res.data.pagination.total)
          this.waitingForPaymentTotal = res.data.inReviewTotal
          this.pendingTotal = res.data.pendingTotal
          this.pendingRequests.map((request) => {
            request.checked = false;
          })
        })
        .catch()
    },
    loadScore(id) {
      this.$http.get(`/api/v1/funding_requests/get-score/${id}`)
        .then((res) => {
          this.pendingRequests.map((request) => {
            if (request.id === id) {
              request.score = Number.parseInt(res.data.score);
            }
          })
        })
        .catch()
    },
    showSelectNav() {
      this.showSelect = !this.showSelect;
    },
    getRequestsByStatus(status) {
      this.status = status
      this.getPendingRequests()
    },
    selectAll(val) {
      if (val === false) {
        this.pendingRequests.map((request) => {
          request.checked = true;
          this.selectedCount++;
        })
      } else {
        this.pendingRequests.map((request) => {
          request.checked = false;
          this.selectedCount--;
        })
      }
    },
    deleteFunding(id, deleteSelected = false) {
      if (confirm('Are you sure?')) {
        this.$http
          .delete(`/api/v1/funding-requests/delete/${id}`)
          .then(() => {
            if (deleteSelected && this.getSelectedIds().length > 0) {
              this.toggleCheckboxItem(id)
              this.deleteFunding(this.getSelectedIds()[0], true)
            }

            this.getPendingRequests()
          })
      }
    },
    toggleModal(modalName, fundingId = null, updateTableData = false) {
      this.modals[modalName] = !this.modals[modalName]
      this.selectedFundingRequestId = fundingId
      if (updateTableData) {
        this.getPendingRequests()
      }
    },
    pay(fr) {
      this.ui.pay.loading.push(fr.id);
      this.$http
        .post(`/api/v1/funding_requests/pay/${fr.id}`)
        .then(() => {
          this.pendingRequests = this.pendingRequests.filter((f) => f.id !== fr.id);
          this.alertSuccess(`${fr.borrowerName} has been successfully paid`)
        })
        .catch((e) => {
          let err = this.getFirstErrorFromResponse(e.response)
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', {
            msg: `Error! ${err}`,
            status: 'error',
            delay: 3000
          });
        }).finally(() => {
          this.ui.pay.loading = this.ui.pay.loading.filter(id => id !== fr.id)
        })
    },
    openPaymentCustomModal(id) {
      this.selectedFundingRequestId = id;
      this.modals.paymentModal = true;
      this.payment.customFee = true;
    },
    toggleCheckboxItem(id) {
      this.selectedCount = 0
      this.pendingRequests.map(item => {
        if (item.id === id) {
          item.checked = !item.checked
        }

        if (item.checked)
          this.selectedCount++
      })

      let checkbox = document.getElementById('checkbox-select')
      if (this.selectedCount > 0 && this.selectedCount < this.pendingRequests.length) {
        checkbox.indeterminate = true
      } else if (this.selectedCount === this.pendingRequests.length) {
        checkbox.indeterminate = false
        checkbox.checked = true
      } else {
        checkbox.indeterminate = false
        checkbox.checked = false
      }
    },
    searchFundingRequests() {
      if (this.search.timer) {
        clearTimeout(this.search.timer)
      }

      this.search.timer = setTimeout(() => {
        this.getPendingRequests()
      }, 800)
    },
    getSelectedIds() {
      let ids = [];

      this.pendingRequests.map(item => {
        if (item.checked) {
          ids.push(item.id)
        }
      })

      return ids
    },
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    editAllow() {
      return this.selectedCount !== 1
    },
    deleteAllow() {
      return this.selectedCount === 0
    }
  },
  beforeMount() {
    this.getPendingRequests()
    if (this.$route.query.fr) {
      this.toggleModal('fundingDetails', Number.parseInt(this.$route.query.fr));
    }
  }
}
</script>

<style lang="scss" scoped>
div.table {
  min-height: 200px;

}
.table {
  tbody {
    tr {
      vertical-align: middle;

      td {
        color: rgba(0, 0, 0, .8);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;

        &:not(:first-child) {
          padding-left: 0;
        }
      }

      .btn {
        max-height: 32px;
        height: 32px;
        font-size: 12px;
        line-height: 1.5;
      }

      .agents-avatars {
        img {
          height: 34px;
          width: 34px;
          border: 2px solid #F6F6F6;
          border-radius: 50%;

          &:nth-child(n + 2) {
            margin-left: -5px;
          }
        }
      }
    }
  }

  .actions-buttons {
    .btn {
      margin-right: 0.25rem;
    }
  }
}

.modal {
  .tab-list {
    padding: 0;

    li {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.form-check-input {
  border-width: 2px;
}
#customPay {
  max-width: 85px;
  width: 100%;
  pointer-events: none;
  opacity: .6;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
  ::v-deep {
    .dropdown-theme {
      min-width: 120px;
    }
    .tippy-popper {
      top: 5px!important;
      left: -40px!important;
    }
  }
  .custom-pay {
    ul {
      padding: 10px 0;
      list-style: none;
      margin: 0;
      li {
        color: #000000;
        padding: 5px 10px;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        cursor: pointer;
        transition: all .3s;
        text-align: initial;
        &:hover {
          background-color: rgba(0,0,0,.05);
        }
      }
    }
  }
  .tippy-dropdown {
    justify-content: space-between;
    height: 31px;
    border: 1px solid #8A8A8A;
    border-radius: 8px;
    cursor: pointer;
    z-index: 3;
    &.tippy-active {
      img {
        right: 3px;
      }
    }
    button {
      border: none;
      background: none;
      padding: 0;
    }
    .r-line {
      position: relative;
      z-index: 99999;
      width: 40%;
      height: 100%;
      border-left: 1px solid #8A8A8A;
    }
    img {
      top: 6px;
      right: 5px;
      z-index: 0;
    }
  }
}
</style>
