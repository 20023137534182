<template>
  <div id="paymentModal" class="modal">
    <div class="modal-bg" @click="close(null, false)"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Payment</h3>
      </div>
      <div class="modal-body">
        <div v-if="ui.loading" class="text-center mt-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <h6>
            Pay "{{ fundingRequest.borrowerName }}" funding by {{ fundingRequest.mlo.fullName }} with temporary fee
          </h6>
          <div v-if="!isCustom" class="modal-body-container border-0">
            <div class="modal-body-container_title">Payment Breakdown</div>
            <div class="table">
              <table class="table">
                <thead>
                <tr>
                  <th>Payment Split</th>
                  <th></th>
                  <th>Share</th>
                  <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>John Smith</td>
                  <td>
                    <span class="color-light-grey">John Smith</span>
                  </td>
                  <td>80%</td>
                  <td><b>$4,000.00</b></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <!-- MLO -->
            <div class="modal-body-container mt-4 border-0 mb-0 pb-4">
              <div class="modal-body-container_title">MLO Fee</div>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="fieldMlo">MLO Percentage</label>
                  <input id="fieldMlo" v-model="fees.mlo.bonus" class="form-control" type="text" disabled/>
                </div>
                <div class="col-md-6 form-group">
                  <label for="fieldAmount">Amount</label>
                  <input id="fieldAmount" v-model="fees.mlo.amount" class="form-control" type="text" disabled/>
                </div>
                <div class="col-12">
                  <span class="notice">
                    {{ fundingRequest.mlo.fullName }} will earn this percent of total origination charged
                  </span>
                </div>
              </div>
            </div>
            <!-- Receiver -->
            <div class="modal-body-container border-0 mb-0 pb-2">
              <div class="row border-0">
                <div class="col-8">
                  <div class="form-group full-width">
                    <label :for="'fieldReceivers' + fundingRequest.mlo.id">Bonus Receiver</label>
                    <multiselect :id="'fieldReceivers' + fundingRequest.mlo.id"
                                 v-model="fees.agentFee.receiver"
                                 :close-on-select="true"
                                 :options="fees.mloBonusReceivers"
                                 :searchable="true"
                                 :show-labels="false"
                                 class="full-width"
                                 track-by="id"
                                 label="fullName"
                                 open-direction="bottom"
                                 placeholder="">
                    </multiselect>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label :for="'fieldBonus' + fundingRequest.mlo.fee.id">Bonus</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">%</div>
                      </div>
                      <input :id="'fieldBonus' + fundingRequest.mlo.fee.id" v-model="fees.agentFee.bonus"
                             class="form-control" type="number" step="0.5" aria-label="bns"
                             @change="calculateMloFee($event.target.value)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body-container border-0 mb-0 pb-2" v-for="(bonus, key) in fees.bonuses" :key="bonus.id">
              <div class="row border-0">
                <div class="col-5">
                  <div class="form-group full-width">
                    <label :for="'fieldReceivers' + bonus.id">Bonus Receiver</label>
                    <multiselect :id="'fieldReceivers' + bonus.id"
                                 v-model="bonus.receiver"
                                 :close-on-select="true"
                                 :options="fees.mloBonusReceivers"
                                 :searchable="true"
                                 :show-labels="false"
                                 class="full-width"
                                 track-by="id"
                                 label="fullName"
                                 open-direction="bottom"
                                 placeholder="">
                    </multiselect>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label :for="'fieldTypes' + bonus.id">Type</label>
                    <multiselect :id="'fieldTypes' + bonus.id"
                                 v-model="bonus.type"
                                 :close-on-select="true"
                                 :options="mloTypes"
                                 :searchable="false"
                                 :show-labels="false"
                                 open-direction="bottom"
                                 placeholder="Select type" @select="selectType($event, key)">
                    </multiselect>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label :for="'fieldBonus' + bonus.id">Bonus</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div v-if="bonus.type === flat_amount" class="input-group-text">$</div>
                        <div v-if="bonus.type === basis_points" class="input-group-text">bps</div>
                        <div v-if="bonus.type === percentage" class="input-group-text">%</div>
                      </div>
                      <input :id="'fieldBonus' + bonus.id" v-model="bonus.bonus" class="form-control" type="number"
                             @change="calculateMloFee($event.target.value)" step="0.5" aria-label="bns">
                    </div>
                  </div>
                </div>
                <div class="col-1 my-auto">
                  <button class="btn btn-control" @click="removeBonus(bonus.id)">
                    <img alt="" src="@/assets/icons/icon-delete.svg">
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 add-bonus modal-body-container mt-2">
              <div class="d-flex align-items-center">
                <img alt="" src="@/assets/icons/bold-icon-sm add blue.svg" class="cursor-pointer" @click="addBonus">
                <span class="pl-2 cursor-pointer" @click="addBonus">Add Bonus</span>
              </div>
            </div>

            <!-- Processor -->
            <div class="modal-body-container mt-3" v-if="fundingRequest.isProcessorUsed">
              <div class="modal-body-container_title">
                Processor ({{ fees.processorFee.processor.fullName }})
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label for="fieldProcessorType">Processor Bonus Type</label>
                    <multiselect id="fieldProcessorType"
                                 v-model="fees.processorFee.type"
                                 :close-on-select="true"
                                 :options="mloTypes"
                                 :searchable="false"
                                 :show-labels="false"
                                 open-direction="bottom"
                                 placeholder="Select type">
                    </multiselect>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="fieldProcessorBonus">Processor Bonus</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div v-if="fees.processorFee.type === flat_amount" class="input-group-text">$</div>
                        <div v-if="fees.processorFee.type === basis_points" class="input-group-text">bps</div>
                        <div v-if="fees.processorFee.type === percentage" class="input-group-text">%</div>
                      </div>
                      <input id="fieldProcessorBonus"
                             v-model="fees.processorFee.bonus"
                             class="form-control"
                             type="number" @change="calculateAmount(fees.processorFee.bonus, fees.processorFee.type)">
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="fieldProcessorBonus">CMC Bonus</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div v-if="fees.processorFee.type === flat_amount" class="input-group-text">$</div>
                        <div v-if="fees.processorFee.type === basis_points" class="input-group-text">bps</div>
                        <div v-if="fees.processorFee.type === percentage" class="input-group-text">%</div>
                      </div>
                      <input id="fieldCMCBonus"
                             v-model="fees.processorFee.cmcBonus"
                             class="form-control"
                             type="number" @change="calculateAmount(fees.processorFee.cmcBonus, fees.processorFee.type)">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-body-container mt-3" v-if="fees.loaFee.loa">
              <div class="modal-body-container_title">
                LOA ({{ fees.loaFee.loa.fullName }})
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group full-width">
                    <label :for="'fieldReceivers' + fees.loaFee.loa.id">LOA</label>
                    <multiselect :id="'fieldReceivers' + fees.loaFee.loa.id"
                                 v-model="fees.loaFee.loa"
                                 :close-on-select="true"
                                 :options="fees.loas"
                                 :searchable="true"
                                 :show-labels="false"
                                 class="full-width"
                                 track-by="id"
                                 label="fullName"
                                 open-direction="bottom"
                                 placeholder="">
                    </multiselect>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="fieldProcessorType">LOA Bonus Type</label>
                    <multiselect id="fieldProcessorType"
                                 v-model="fees.loaFee.type"
                                 :close-on-select="true"
                                 :options="mloTypes"
                                 :searchable="false"
                                 :show-labels="false"
                                 open-direction="bottom"
                                 placeholder="Select type">
                    </multiselect>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="fieldProcessorBonus">Bonus</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div v-if="fees.loaFee.type === flat_amount" class="input-group-text">$</div>
                        <div v-if="fees.loaFee.type === basis_points" class="input-group-text">bps</div>
                        <div v-if="fees.loaFee.type === percentage" class="input-group-text">%</div>
                      </div>
                      <input id="fieldProcessorBonus"
                             v-model="fees.loaFee.bonus"
                             class="form-control"
                             type="number" @change="calculateAmount(fees.loaFee.bonus, fees.loaFee.type)">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Summary -->
            <div class="modal-body-container border-0 mt-3">
              <div class="modal-body-container_title">Summary</div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckCheckedDisabled" :checked="chargeAdminFee" disabled>
                <label class="form-check-label" for="flexCheckCheckedDisabled">Charge admin fee</label>
              </div>
              <div class="table">
                <table class="table">
                  <tbody>
                  <tr>
                    <td>Loan amount</td>
                    <td>{{ convertToCurrency(fundingRequest.loanAmount) }}</td>
                  </tr>
                  <tr>
                    <td>Origination</td>
                    <td>{{ convertToCurrency(fundingRequest.totalOriginationCharged) }}</td>
                  </tr>
                  <tr>
                    <td>Reimbursements</td>
                    <td>{{ convertToCurrency(fundingRequest.reimbursedAmount) }}</td>
                  </tr>
                  <tr>
                    <td>Cure</td>
                    <td>{{ fundingRequest.cureAmount ? convertToCurrency(fundingRequest.cureAmount) : '$0.00' }}</td>
                  </tr>
                  <tr>
                    <td>{{ fundingRequest.mlo.fullName }}</td>
                    <td>{{ convertToCurrency(getMloSummary()) }}</td>
                  </tr>
                  <tr>
                    <td>{{ fees.agentFee.receiver.fullName }}</td>
                    <td>
                      {{ convertToCurrency(receiverEarnings()) }}
                    </td>
                  </tr>
                  <tr v-if="isW2Payment">
                    <td>
                      {{ mloIsMainReceiver ? fundingRequest.mlo.fullName : fees.agentFee.receiver.fullName }} (W2 Payment)
                    </td>
                    <td>$500</td>
                  </tr>
                  <tr v-if="isW2Payment">
                    <td>CMC (W2 Payment Tax)</td>
                    <td>$55</td>
                  </tr>
                  <tr v-for="(bonus, index) in fees.bonuses" :key="index">
                    <td>{{ !bonus.receiver ? 'CMC' : bonus.receiver.fullName }}</td>
                    <td v-if="fundingRequest.mlo.branchId === 14 && index === 0">
                      {{ convertToCurrency(calculateAmount(bonus.bonus, bonus.type) - getLOABonusAmount()) }}
                    </td>
                    <td v-else>{{ convertToCurrency(calculateAmount(bonus.bonus, bonus.type)) }}</td>
                  </tr>
                  <tr v-if="fundingRequest.loaFee">
                    <td>{{ fees.loaFee.loa.fullName }} (LOA)</td>
                    <td>{{ convertToCurrency(calculateAmount(fees.loaFee.bonus, fees.loaFee.type)) }}</td>
                  </tr>
                  <tr v-if="fundingRequest.isProcessorUsed">
                    <td>{{ fees.processorFee.processor.fullName }} Processing fee earnings</td>
                    <td>{{ convertToCurrency(calculateAmount(fees.processorFee.bonus, fees.processorFee.type)) }}</td>
                  </tr>
                  <tr v-if="fundingRequest.isProcessorUsed">
                    <td>CMC Processing fee</td>
                    <td>{{ convertToCurrency(calculateAmount(fees.processorFee.cmcBonus, fees.processorFee.type)) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="ui.loading === false" class="modal-footer justify-content-between">
        <div>
          <base-button title="Pay" @click-btn="pay" :loading="ui.saving" action="secondary-default" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'PaymentModal',
  props: {
    customFee: {
      type: Boolean
    },
    fundingRequestId: {
      type: Number,
      required: true
    }
  },
  components: {
    Multiselect,
    BaseButton
  },
  data() {
    return {
      fundingRequest: {},
      isCustom: false,
      fees: {
        mlo: {
          type: 'percentage',
          bonus: 0.00,
          amount: 0.00
        },
        agentFee: {
          receiver: null,
          bonus: 0.00
        },
        processorFee: {
          processor: {},
          type: null,
          bonus: 4.00,
          cmcBonus: 0.00
        },
        loaFee: {
          loa: {},
          type: null,
          bonus: 0.00,
        },
        bonuses: [],
        loas: [],
        mloBonusReceivers: [] // agents
      },
      mloTypes: ['percentage', 'basis_points', 'flat_amount'],
      ui: {
        loading: false,
        saving: false
      },
      percentage: 'percentage',
      basis_points: 'basis_points',
      flat_amount: 'flat_amount',
      chargeAdminFee: true,
      isW2Payment: false,
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      this.$http
        .get(`/api/v1/funding_requests/pay/${this.fundingRequestId}`)
        .then(res => {
          this.fundingRequest = res.data.fundingRequest
          this.isCustom = this.customFee;
          this.fees.loas = res.data.loas
          this.ui.loading = false;
        })
        .catch()
    },
    selectType(val, index) {
      this.fees.bonuses[index].type = val
      this.calculateMloFee()
    },
    addBonus() {
      this.fees.bonuses.push({
        bonus: 0.00,
        id: Math.random(),
        receiver: {},
        type: 'flat_amount',
        new: true
      })
    },
    removeBonus(id) {
      this.fees.bonuses = this.fees.bonuses.filter((item) => {
        return item.id !== id
      })
      this.calculateMloFee()
    },
    addCompanyBonus() {
      if (this.fees.companyBonuses.length) {
        let countCompanyBonuses = this.fees.companyBonuses[this.fees.companyBonuses.length - 1];
        this.fees.companyBonuses.push({id: countCompanyBonuses.id + 1, type: 'Percentage'});
      } else {
        this.fees.companyBonuses.push({id: 1, type: 'Percentage'});
      }
    },
    removeCompanyBonus(id) {
      this.fees.companyBonuses = this.fees.companyBonuses.filter(function (el) {
        return el.id !== id;
      });
    },
    payNow() {
      this.ui.saving = true;
      this.$http
        .post(`/api/v1/funding_requests/pay/${this.fundingRequest.id}`)
        .then(() => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Funding request successfully paid!',
            status: 'success',
            delay: 2000
          });
          this.ui.saving = false;
          this.close(null, true)
        })
        .catch()
    },
    payWithCustomFee() {
      this.ui.saving = true;
      let formData = {
        agent_fee: {
          receiver_id: this.fees.agentFee.receiver.id,
          bonus: this.fees.agentFee.bonus
        },
      }
      let bonuses = [];
      this.fees.bonuses.forEach((item) => {
        bonuses.push({ receiver_id: item.receiver.id, type: item.type, bonus: item.bonus })
      })

      formData.agent_fee_bonus = bonuses

      if (this.fundingRequest.isProcessorUsed) {
        formData.processor_fee = {
          type: this.fees.processorFee.type,
          bonus: this.fees.processorFee.bonus,
          cmc_bonus: this.fees.processorFee.cmcBonus,
        }
      }

      if (this.fees.loaFee) {
        formData.loa_fee = {
          loa_id: this.fees.loaFee.loa.id,
          type: this.fees.loaFee.type,
          bonus: this.fees.loaFee.bonus,
        }
      }

      this.$http
        .post(`/api/v1/funding_requests/custom-pay/${this.fundingRequestId}`, formData)
        .then(() => {
          this.ui.saving = false;
          this.pushAlert('success', 'Funding request successfully paid!')
          this.close(null, true)
        })
        .catch()
    },
    pay() {
      this.customFee
        ? this.payWithCustomFee()
        : this.payNow()
    },
    getActiveAgents() {
      this.$http
        .get('/api/v1/form/agents-active')
        .then(res => this.fees.mloBonusReceivers = res.data)
    },
    close(id, updateTableData) {
      this.$emit('close', 'paymentModal', id, updateTableData)
    },
    getCustomFeeFormData() {
      this.ui.loading = true
      this.$http
        .get(`/api/v1/funding_requests/custom-pay/${this.fundingRequestId}`)
        .then(res => {
          this.fundingRequest = res.data.fundingRequest
          let cmcReceiver = {id: null, fullName: 'CMC'}

          this.fees.agentFee.receiver = !this.fundingRequest.mlo.fee.receiver
            ? cmcReceiver
            : this.fundingRequest.mlo.fee.receiver;
          this.fees.agentFee.bonus = this.fundingRequest.mlo.fee.bonus

          if (this.fundingRequest.isProcessorUsed) {
            this.fees.processorFee.processor = this.fundingRequest.processor
            this.fees.processorFee.type = this.fundingRequest.processor.fee.type
            this.fees.processorFee.bonus = this.fundingRequest.processor.fee.processorFee
            this.fees.processorFee.cmcBonus = this.fundingRequest.processor.fee.cmcBonus
          }

          if (this.fundingRequest.loaFee) {
            this.fees.loaFee.loa = this.fundingRequest.loaFee.loa
            this.fees.loaFee.type = this.fundingRequest.loaFee.type
            this.fees.loaFee.bonus = this.fundingRequest.loaFee.bonus
          }

          this.fees.mloBonusReceivers = res.data.users
          this.fees.mloBonusReceivers.unshift(cmcReceiver)

          // get fee
          this.fees.bonuses = this.fundingRequest.mlo.fee.bonuses
          this.fees.bonuses = this.fees.bonuses.map((bonus) => {
            if (!bonus.receiver) {
              bonus.receiver = cmcReceiver
            }

            return bonus
          })

          this.chargeAdminFee = res.data.chargeAdminFee
          // this.isW2Payment = res.data.isW2Payment

          this.calculateMloFee()
          this.fees.mlo.amount = this.calculateAmount(this.fees.mlo.bonus)

          this.isCustom = this.customFee
          this.ui.loading = false;
        })
    },
    calculateMloFee() {
      let bonus = 100
      bonus -= this.fees.agentFee.bonus

      this.fees.bonuses.forEach((item) => {
        if (item.type === 'percentage') {
          bonus -= item.bonus
        }
      })

      this.fees.mlo.bonus = bonus
      this.fees.mlo.amount = this.calculateAmount(bonus)
    },
    calculateAmount(bonus, type = 'percentage') {
      let res = bonus

      if (type === 'percentage') {
        res = this.fundingRequest.totalOriginationCharged * (Number.parseFloat(bonus) / 100)
      }

      if (type === 'basis_points') {
        res = this.fundingRequest.loanAmount * (Number.parseFloat(bonus) / 10000)
      }

      return Number.parseFloat(res)
    },
    getAdditionalFeeAmount() {
      let amount = 0
      this.fees.bonuses.forEach((item) => {
        amount += Number.parseFloat(this.calculateAmount(item.bonus, item.type))
      })

      return amount
    },
    getMloEarning() {
      let result = this.fees.mlo.amount

      if (this.mloIsMainReceiver) {
        result -= this.reduceBonusesFromMainReceiver()
        result -= this.getProcessorBonusAmount()
        result -= this.getLOABonusAmount()
      }

      return result;
    },
    receiverEarnings() {
      let amount = this.fundingRequest.totalOriginationCharged * (this.fees.agentFee.bonus / 100)

      if (!this.mloIsMainReceiver) {
        amount += Number.parseFloat(this.fundingRequest.reimbursedAmount)
        if (this.fundingRequest.totalOriginationCharged > 0 && this.chargeAdminFee) {

          if (this.fundingRequest.mlo.branchId === 14) {
            amount -= 565.00 // Admin Fee
          } else if (this.fundingRequest.mlo.branchId === 19) {
            amount -= 595.00
          } else {
            amount -= 65.00 // Admin Fee
          }
        }

        if (this.fundingRequest.totalOriginationCharged > 0 && this.isW2Payment && amount > 555) {
          amount -= 500.00 // W2 Payment
          amount -= 55.00 // W2 Payment Tax
        }

        amount -= this.reduceBonusesFromMainReceiver()
        amount -= this.getProcessorBonusAmount()

        if (this.fundingRequest.mlo.branchId !== 14) {
          amount -= this.getLOABonusAmount()
        }
      }

      return amount;
    },
    getMloSummary() {
      let total = this.getMloEarning();

      if (this.mloIsMainReceiver) {
        total += Number.parseFloat(this.fundingRequest.reimbursedAmount)
        if (this.fundingRequest.totalOriginationCharged > 0 && this.chargeAdminFee) {
          total -= 65.00 // Admin Fee
        }

        if (this.fundingRequest.totalOriginationCharged > 0 && this.isW2Payment && total > 555) {
          total -= 500.00 // W2 Payment
          total -= 55.00 // W2 Payment Tax
        }
      }

      return total
    },
    getProcessorBonusAmount() {
      let amount = 0.00

      if (this.fundingRequest.isProcessorUsed) {
        amount += this.calculateAmount(this.fees.processorFee.bonus, this.fees.processorFee.type)
        amount += this.calculateAmount(this.fees.processorFee.cmcBonus, this.fees.processorFee.type)
      }

      return Number.parseFloat(amount)
    },
    getLOABonusAmount() {
      let amount = 0.00

      if (this.fundingRequest.loaFee) {
        amount += this.calculateAmount(this.fees.loaFee.bonus, this.fees.loaFee.type)
      }

      return Number.parseFloat(amount)
    },
    reduceBonusesFromMainReceiver() {
      let amount = 0;

      this.fees.bonuses.filter((bonus) => {
        if (bonus.type === 'flat_amount') {
          amount += this.calculateAmount(bonus.bonus, bonus.type)
        }
        if (bonus.type === 'basis_points') {
          amount += this.calculateAmount(bonus.bonus, bonus.type)
        }
      })
      console.log(amount)
      return amount;
    },
  },
  computed: {
    mloPercentage() {
      return 1;
    },
    mloAmount() {
      return 1;
    },
    mloIsMainReceiver() {
      return this.fees.mlo.bonus > Number.parseFloat(this.fees.agentFee.bonus).toFixed(2);
    },
  },
  created() {
    if (this.customFee) {
      this.getCustomFeeFormData()
    } else {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
}

table.table {
  thead {
    th {
      opacity: 0.46;
      color: rgba(0, 0, 0, 1);
      font-size: 0.875rem;
      letter-spacing: 0;
      line-height: 1.5rem;
      padding: 12px;
    }
  }

  tbody {
    td {
      color: rgba(0, 0, 0, 1);
      font-size: 0.875rem;
      letter-spacing: 0;
      font-weight: normal;
      line-height: 1.5rem;
      padding: 12px;

      span {
        &.color-light-grey {
          opacity: 0.46;
          color: rgba(0, 0, 0, 1);
          font-size: 0.8125rem;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 1.5rem;
        }
      }

      b {
        color: rgba(0, 0, 0, 1);
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.5rem;
      }
    }
  }
}

span {
  &.notice {
    display: block;
    opacity: 0.46;
    color: rgba(0, 0, 0, 1);
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 1.125rem;
    font-weight: normal;
    margin-top: 0.75rem;
  }
}

.add-bonus {
  span {
    color: rgba(0, 0, 0, 1);
    font-size: 0.9375rem;
    letter-spacing: 0;
    line-height: 1.375rem;
    font-weight: normal;
  }
}

.btn {
  .text-light {
    color: rgba(255, 255, 255, 1);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 0.875rem;
  }
}

.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

</style>
